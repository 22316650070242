import styled from 'styled-components'

const Wrapper = styled.div`
  width:100%;
  display:flex;
  flex-wrap:wrap;
  .categoryCardsWrapper{
    display:flex;
    width:100%;
    justify-content:space-between;
    margin-bottom:24px;
  }
  .firstContainer{
    width: 46.2%;
    .topCardWrapper{
      display:flex;
      flex-wrap:wrap;
      justify-content:space-between;
    }
  }
  .secondContainer{
    width:53.8%;
    padding-left: 24px;
    .scoreCard{
      width:100%;
      background:white;
      border-radius:6px;
    }
  }
  .winsRisksCard{
    width:100%;
    display:flex;
    justify-content: space-between;
    margin-top:15px;
    .winsCard{
      width:calc((100% - 48px) / 3);
      display:flex;
      max-width: 610px;
    }
  }
`;

const TopCard = styled.div`
  width: ${({width}) => width ? width : "275px"};
  height: ${({height}) => height ? height : "230px"}; // 230px;
  background: white;
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : '0px'};
  border-radius:6px;
  padding: 12px;
  .progressBar{
    width:100%;
    margin-top: 20px;
  }
  .heading{
    width:100%;
    display:flex;
    .icon{
      width: ${({iconWidth}) => iconWidth ? iconWidth : "60px"}; // 60px;
      display: flex;
      background: ${({shadowColor})=> shadowColor ? shadowColor : ""}; 
      display: flex; 
      justify-content: center; 
      align-items: center;
      border-radius: 6px;
      >img{
        width: 60px;
        height: 60px;
      }
    }
    .name{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      color:#005C87;
      display:flex;
      align-items:center;
      padding-left:12px;
      width: calc(100% - 48px);
    }
  }
  .body{
    width:100%;
    height:111px;

    .points{
      width:100%;
      display:flex;
      font-family: Rubik-Medium;
      font-size: 24px;
      line-height: 24px;
      color: ${({color}) => color ? color : '#76ab78'};
      padding-left:0px;
      padding-top: ${({ paddingTop }) => paddingTop ? paddingTop : '35px'};
      padding-bottom:10px;
      .taken{
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(0, 92, 135, 0.6); // #005C87;
        align-items: end;
        display: flex;
        padding-left: 12px;
      }
    }
    .line{
      width:108px;
      height:1px;
      background: #005c871a;
      margin-left:15px;
      margin-bottom:10px;
      border: 1px;
      border-color: rgba(0, 92, 135, 0.1);
    }
    .subText{
      font-family: Rubik;
      font-size: 14px;
      font-weight: 500;
      color:rgba(118, 171, 120, 1);
      line-height: 20px;
      display:flex;
      >span{
        color:rgba(159, 201, 137, 1);
        font-family: Rubik-Medium;
        font-size: 14px;
        font-weight: 500;        
      }
      .subContentText{
        color: #005C8799;
        font-family: Rubik-regular;
      }
    }
  }

`;

const ProgressBar = styled.div`
  width:100%;
  height: ${({ height }) => height ? height : '15px'};
  background: ${({ background }) => background ? background : '#005C870D'};
  border-radius: ${({ radius }) => radius ? '0px' : '50px'};
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : '0px'};
  display:flex;
  position: relative;
  .percentage-value{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #005C87;
    display:flex;
    align-items:center;
    padding-left: 5px;
  }
  .progress{
    width: ${({ width }) => width ? `${width}%` : '0%'};
    height: ${({ height }) => height ? height : '15px'};
    background: ${({ backgroundActive }) => backgroundActive ? backgroundActive : 'rgba(159, 201, 137, .15)'};
    border-radius: ${({ radius }) => radius ? '0px 12px 12px 0px' : '50px'};
    font-family: Rubik;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color:white;
    display:flex;
    justify-content:flex-end;
    align-items:center;
    padding-right: ${({ paddingRight }) => paddingRight ? paddingRight : '15px'};
    position:relative;


    ${'' /* &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 20px;
      height: 20px;
      background: ${({ backgroundActive }) => backgroundActive ? backgroundActive : 'rgba(159, 201, 137, .15)'};
      border-radius: 50%;
      border: 2px solid #AF87C1; 
      z-index: 1;
    } */}
    
    ${'' /* &:before {
      content: "";
      right: -20px;
      width: 13px;
      height: 40px;
      background: #fff; 
      border-top-left-radius: 80px;
      border-bottom-left-radius: 80px;
      z-index: 0;
    } */}

    ${'' /* &:after {
      content: "";
      width: 10px;
      height: 20px;
      background: ${({ backgroundActive }) => backgroundActive ? backgroundActive : 'rgba(159, 201, 137, .15)'};
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    } */}
  }
  .showPercent{
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: ${({ width }) => width>16 ? 'absolute' : 'sticky' };
    margin-left: ${({ width }) => width<16 && '10px' };
    left: ${({ width }) => width>16 ? `${width}px` : '0px'};
    top: 5px;
    font-family: 'Rubik-medium';
    font-size: 16px;
    color: ${({width})=> width>16 ? "#fff": "#005C87"};
  }
  .percentageValue{
    font-family: Rubik-Medium;
    font-size: 14px;
    margin: auto;
    margin-right: 0px;
    padding-right:10px;
    line-height: 16px;
    color: ${({ backgroundActive }) => backgroundActive ? backgroundActive : 'rgba(159, 201, 137, .15)'};
    text-align:end;
    justify-content:flex-end;
  }
  `;

const ScoreCard = styled.div`
  width:100%;
  background: white;
  border-radius:6px;
  display:block;
  padding: ${({ padding }) => padding ? padding : '12px 12px 0 12px'};
  // margin-top:25px;
  .heading{
    font-family: "Rubik-Medium";
    font-size: 18px;
    line-height: 24px;
    color:#005c87;
    padding:25px;
    border-bottom: 1px solid rgba(0, 92, 135, 0.3);
  }
  .body{
    width:100%;
    padding:30px 35px 5px 35px;
  }
  .winsHeading{
    font-family: "Rubik-Medium";
    font-size: 18px;
    line-height: 24px;
    color:#005C87;
    padding:0px 0 12px 0px;
    border-bottom: 1px solid #005C871A;
    background: #005C87;
    border-radius: 6px 6px 0 0;
    display:flex;
    .icon{
      width:24px;
      height:24px;
      margin-right:8px;
      >img{
        width:24px;
        height:24px;
      }
    }
  }
  .winsBody{
    width:100%;
    padding:0px;
    margin-top: 12px;
    .noData{
      display:flex;
      width:100%;
      flex-wrap:wrap;
      .image{
        width:100%;
        height:100%;
        display:flex;
        margin:auto;
        margin-top:70px;
        justify-content:center;
        >img{
          width:auto;
          height:132px;
        }
      }
      .empty{
        font-family: Rubik;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color:#005c87;
        display:flex;
        width:100%;
        justify-content:center;
        margin-top:15px;
        padding: 0px 70px;
        margin-bottom: 12px;
      }
    }
  }
  `;

const ScoreCardBar = styled.div`
  width:100%;
  display:flex;
  margin-bottom: 25px;
  gap: 20px;
  .image{
    width:50px;
    height:50px;
    margin-right:25px;
    background: ${({bgColor})=> bgColor ? bgColor : ''};
    >img{
      width:50px;
      height:50px;
    }
  }
  .wrapper{
    display:block;
    width: 100%;
    .score{
      display:flex;
      margin-bottom: 5px;
      .title{
        font-family: Rubik;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #005C8799;
        display:flex;
      }
      .points{
        font-family: Rubik-regular;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #005C8799;
        display:flex;
        margin:auto;
        margin-right:0px;
      }
    }
  }
  `;

const WellnessWinsCard = styled.div`
  width:100%;
  display:flex;
  border: ${({ border }) => border ? "1px solid #007AB14D" : 'none'};
  padding:12px;
  margin-bottom: 12px;
  border-radius:6px;
  .image{
    width:50px;
    height:50px;
    // margin-bottom:15px;
    margin: auto;
    >img{
      width:50px;
      height:50px;
    }
  }
  .name{
    font-family: Rubik;
    font-size: 16px;
    line-height: 20px;
    color:#005C87;
    margin: auto 0 auto auto;
    align-items: center;
    display: flex;
    width:calc(100% - 70px)
  }
  .percentageCard{
    width:70px;
    height: 50px;
    background: rgba(118, 171, 120, 0.20);
    display:flex;
    align-items:center;
    margin:auto auto auto 0;
    font-family: Rubik-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color:#005C87;
    justify-content: center;
    background: ${({ background }) => background ? background : '#76AB7826'};
    border-radius:6px;
  }
  .arrow{
    width: 15px;
    height:15px;
    margin:auto;
    margin-right:0px;
    margin-left:0px;
    >img{
      width:100%;
      height:100%;
      margin-bottom: 5px;
    }
  }
  .percentage{
    width:38px;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: #9FC989;
    display:flex;
    margin:auto;
    margin-right:15px;
    margin-left:5px;
    color: ${({color}) => color ? color : '#9FC989'};
  }
  `;

const CircleNew = styled.div`
  width: ${({width}) => width ? width : '136px'};
  height: ${({height}) => height ? height : '136px'};
  border-radius: 50%;
  background: white;
padding: 10px;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
margin-top:5px;
`;

const CircularBar = styled.div`
.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: rgba(0, 92, 135, 0.10);
}
.circle-underline{
  width:85px;
  height:85px;
  border:1px solid;
}
.circle-progress {
  stroke: ${({stroke}) => stroke ? stroke : '#F4AAA9'};
  stroke-linecap: square;
}

.circle-text {
  font-family: Rubik-Medium;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  fill:${({stroke}) => stroke ? stroke : '#F4AAA9'}
}

.points-earned{
  font-family: Rubik;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0px;
  text-align: center;
  fill: rgba(0, 92, 135, 0.6);
}
`;

export { Wrapper, TopCard, ProgressBar, ScoreCardBar, ScoreCard, WellnessWinsCard, CircleNew, CircularBar }