import { CircularBar } from './styles';
import React, { Component } from 'react';
import PropTypes from "prop-types";

class CircularProgressBar extends Component {

  render() {
    const sqSize = this.props.sqSize;
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - dashArray * this.props.percentage / 100;

    return (
      <CircularBar stroke={this.props.color}>
        <svg
          width={this.props.sqSize}
          height={this.props.sqSize}
          viewBox={viewBox}>
          <circle
            className="circle-background"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`} />
          <circle
            className="circle-progress"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`}
            transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset
            }} />
          <text
            className="circle-text"
            x="50%"
            y={this.props.hideText?"50%":"45%"}
            dy=".3em"
            textAnchor="middle">
            {`${this.props.percentage}%`}
          </text>
          <text  x="50%"
            y="60%"
            textAnchor="middle"
            className="points-earned"
          >
            {this.props.textValue?this.props.textValue:""}
          </text>   
        </svg>
      </CircularBar>
    );
  }
}

CircularProgressBar.defaultProps = {
  sqSize: 100,
  percentage: 25,
  strokeWidth: 5
};

CircularProgressBar.propTypes = {

  sqSize: PropTypes.number,
  percentage: PropTypes.number,
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
  hideText: PropTypes.bool,
  textValue: PropTypes.string
};

export default CircularProgressBar;

